@use '@angular/material' as mat;
@use './variables' as variables;

.bg {
    background-color: mat.get-color-from-palette(variables.$finprocess-background, 500) !important;
}

.bg-dark-2 {
    background-color: mat.get-color-from-palette(variables.$finprocess-secondary, 200) !important;
}

.bg-dark {
    background-color: mat.get-color-from-palette(variables.$finprocess-secondary, 500) !important;
}

.color-primary {
    color: mat.get-color-from-palette(variables.$finprocess-primary, 500) !important;
}

.color-warning {
    color: mat.get-color-from-palette(variables.$finprocess-warning, 500) !important;
}

.color-ucba {
    color: #e2001a !important;
}

.color-secondary {
    color: mat.get-color-from-palette(variables.$finprocess-secondary, 500) !important;
}

.color-font {
    color: mat.get-color-from-palette(variables.$finprocess-font, 500) !important;
}

.color-success {
    color: mat.get-color-from-palette(variables.$md-success, A700);
}

.color-danger {
    color: mat.get-color-from-palette(variables.$md-danger, 900);
}

.h-100 {
    height: 100% !important;
}

.w-100 {
    width: 100% !important;
}

.d-none {
    display: none !important;
}

.visiblity-hidden {
    visibility: hidden !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

$spacing-steps: (0: 0,1: 0.25rem,2: 0.5rem,3: 1rem,4: 1.5rem,5: 3rem);

@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $value in $spacing-steps {
        .#{$abbrev}-#{$size} {
            #{$prop}: $value !important;
        }

        .#{$abbrev}t-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-top: $value !important;
        }

        .#{$abbrev}r-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-right: $value !important;
        }

        .#{$abbrev}b-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-bottom: $value !important;
        }

        .#{$abbrev}l-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-left: $value !important;
        }
    }
}

@each $size, $value in $spacing-steps {
    .m-n#{$size} {
        margin: -$value !important;
    }

    .mt-n#{$size},
    .my-n#{$size} {
        margin-top: -$value !important;
    }

    .mr-n#{$size},
    .mx-n#{$size} {
        margin-right: -$value !important;
    }

    .mb-n#{$size},
    .my-n#{$size} {
        margin-bottom: -$value !important;
    }

    .ml-n#{$size},
    .mx-n#{$size} {
        margin-left: -$value !important;
    }
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

/* stylelint-disable order/properties-alphabetical-order */
.hyphens {
    -ms-word-break: break-all;
    word-break: break-all;

    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;

    -webkit-hyphenate-before: 2;
    -webkit-hyphenate-after: 3;
    /* stylelint-disable-next-line property-no-unknown */
    hyphenate-lines: 3;
}
/* stylelint-enable order/properties-alphabetical-order */


.border-left {
    border-left: 1px solid  mat.get-color-from-palette(variables.$finprocess-grey, 500) !important;
}
