@use '@angular/material' as mat;
@use './app/themes/variables' as variables;
@use 'sass:math';

html,
body {
    height: 100%;
}

body {
    background-color: mat.get-color-from-palette(variables.$finprocess-background, 50);
    color: mat.get-color-from-palette(variables.$finprocess-font, 500);
    margin: 0;
}

.splashscreen-logo {
    margin-bottom: 2em;
    max-width: 640px;
    width: 50vw;
}

.splashscreen-container-index {
    $duration: 1.4s;
    $offset: 187;

    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    .spinner {
        animation: rotator $duration linear infinite;
        height: 50px;
        width: 50px;
    }

    .splashscreen-waiter {
        text-align: center;
    }

    @keyframes rotator {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(270deg); }
    }

    .path {
        animation: dash $duration ease-in-out infinite;
        stroke: mat.get-color-from-palette(variables.$finprocess-primary, 500);
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
    }

    @keyframes dash {
        0% { stroke-dashoffset: $offset; }

        50% {
            stroke-dashoffset: math.div($offset, 4);
            transform: rotate(135deg);
        }

        100% {
            stroke-dashoffset: $offset;
            transform: rotate(450deg);
        }
    }
}

.highlight-on-load {
    .mat-mdc-form-field-flex {
        animation: 0.4s ease-out 0s 1 background-highlight;
    }

    @keyframes background-highlight {
        0% {
            background-color: auto;
            opacity: 0;
        }

        25% {
            background-color: mat.get-color-from-palette(variables.$finprocess-primary, 50);
            opacity: 50;
        }

        50% {
            background-color: mat.get-color-from-palette(variables.$finprocess-primary, 100);
            opacity: 75;
        }

        75% {
            background-color: mat.get-color-from-palette(variables.$finprocess-primary, 50);
            opacity: 100;
        }

        100% {
            background-color: auto;
            opacity: 100;
        }
    }
}

.ignore-animations {
    .mat-mdc-form-field-flex {
        animation-duration: 0s !important;
    }
}

.nav-container {
    margin-bottom: 16px;
}

.nav-item {
    background-color: mat.get-color-from-palette(variables.$finprocess-secondary, 500);
    border-radius: 4px;
    margin-right: 4px;
    padding: 4px;

}

.nav-item-selected {
    border: 2px solid mat.get-color-from-palette(variables.$finprocess-primary, 500);
}


.custom-hr {
    background-color: mat.get-color-from-palette(variables.$md-dark, 50);
    border: 0 none;
    height: 1px;
    margin: 0;
    width: 100%;
}

.finprocess-readonly {
    cursor: auto;
    opacity: 0.6;
    pointer-events: none;
}

.finprocess-readonly-opacity {
    cursor: auto;
    pointer-events: none;
}

.opacity-60 {
    opacity: 0.6;
}

.mat-step-icon {
    background-color: mat.get-color-from-palette(variables.$md-primary, 400) !important;
}

.mat-step-icon-selected {
    background-color: mat.get-color-from-palette(variables.$md-grey, 900) !important;
}

.cursor-grab {
    cursor: grab;
}

/** Styles zu mat-form-field */
/** background color for custom components (texbox-input, decimal-input...)  */
.field-color {
    background: mat.get-color-from-palette(variables.$md-secondary, 300);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

.mdc-text-field--filled.mdc-text-field--disabled {
    background-color: mat.get-color-from-palette(variables.$finprocess-secondary, 300) !important;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}

/* Styles zu mat-button */

/** fix text wrap and button width if space is small*/
/* stylelint-disable-next-line selector-list-comma-newline-after */
.mat-mdc-raised-button, .mat-stroked-button {
    text-wrap: nowrap;
}

/** active primary button text color to white */
.mat-mdc-raised-button:not(:disabled).mat-primary {
    color: mat.get-color-from-palette(variables.$md-light, 50) !important;
}

/** disabled primary button text color to white*/
.mat-mdc-raised-button:disabled.mat-primary {
    color: mat.get-color-from-palette(variables.$md-font, 300) !important;
}

.mdc-fab {
    color: mat.get-color-from-palette(variables.$md-light, 50) !important;
}

/** Checkbox **/
/* stylelint-disable-next-line selector-class-pattern */
.mdc-checkbox__checkmark-path {
    color: mat.get-color-from-palette(variables.$md-light, 50) !important;
}

/* stylelint-disable-next-line selector-max-compound-selectors, selector-class-pattern */
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    background-color: mat.get-color-from-palette(variables.$finprocess-primary, 300);
    border-color: mat.get-color-from-palette(variables.$finprocess-primary, 300);
}

/** unique background color of all form elements */
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: mat.get-color-from-palette(variables.$md-secondary, 300) !important;
}

/** reset unique background to transparent background **/
.mat-form-field-transparent-background {
    background-color: transparent;

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background: transparent !important;
    }
}


/** waiter adjustment */
waiter-dialog {
    align-items: center;
    display: flex;
    height: 80px;
    justify-content: center;
}

/** dialog adjustment */
/* stylelint-disable-next-line selector-class-pattern */
.mat-mdc-dialog-surface.mdc-dialog__surface {
    margin: 0 20px !important;
}
