@use '@angular/material' as mat;
@use '../../../../../../apps/finprocess/src/app/themes/variables.scss' as variables;

.ucba-chip {
    border-radius: 25px;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    font-weight: normal;
    height: 25px;
    margin-right: 1rem;
    min-width: 100px;
    overflow: hidden;
    padding: 0.4rem 1rem;
    position: relative;
    text-align: center;
}


@mixin chip-theme($theme) {
    .ucba-chip {
        background-color: #ddd;

        &.selected {
            background-color: #bfbfbf;
            font-weight: bold !important;
            pointer-events: none;
        }
    }

    .ucba-chip.primary {
        background-color: mat.get-contrast-color-from-palette(variables.$finprocess-primary, 50);
        color: #fff;

        &.selected {
            background-color: mat.get-theme-color($theme, 'primary', 400);
        }
    }
    
    .ucba-chip.accent {
        background-color: mat.get-theme-color($theme, 'accent', 100);

        &.selected {
            background-color: mat.get-theme-color($theme, 'accent', 400);
        }
    }
}



.ucba-chip span {
    animation: ripple 1.5s;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    height: 100px;
    margin-left: -50px;
    margin-top: -50px;
    opacity: 0;
    position: absolute;
    width: 100px;
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale(0);
    }

    to {
        opacity: 0;
        transform: scale(10);
    }
}
// A ripple animation when clicking on the .ucba-chip
