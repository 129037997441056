@use '@angular/material' as mat;

@mixin tile-theme($theme) {    
    .ucba-tile {
        background-color: mat.get-theme-color($theme, 'accent', 'lighter');
        padding: 0.6rem 2rem;
    }
}

.ucba-tile-layout-container {
    column-gap: 0.3rem;
    display: grid;
    overflow: auto;
    row-gap: 0.3rem;
}
