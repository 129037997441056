
@use '@angular/material' as mat;
@use '@fontsource/roboto/scss/mixins' as Roboto;

// Roboto
// ------------------------------------

/* stylelint-disable scss/dollar-variable-pattern */
$fontDir: '/assets/fonts/Roboto';
$fontId: 'roboto';
/* stylelint-enable scss/dollar-variable-pattern */
$subsets: ('latin');
$styles: (normal, italic);
$weights: (100, 300, 400, 500, 700, 900);

@each $subset in $subsets {
    @each $style in $styles {
        @each $weight in $weights {
            @include Roboto.fontFaceCustom(
                $style: $style,
                $weight: $weight,
                $woff2Path: '#{$fontDir}/#{$fontId}-#{$subset}-#{$weight}-#{$style}.woff2',
                $woffPath: '#{$fontDir}/#{$fontId}-#{$subset}-#{$weight}-#{$style}.woff',
            );
        }
    }
}

/* stylelint-disable function-parentheses-space-inside */
$finprocess-typography-config: mat.define-typography-config(
    $font-family:   'Roboto, \'Helvetica Neue\', sans-serif',
    $headline-1:     mat.define-typography-level(114px, 114px, 300, $letter-spacing: -0.05em),
    $headline-2:     mat.define-typography-level(58px, 58px, 400, $letter-spacing: -0.02em),
    $headline-3:     mat.define-typography-level(47px, 50px, 400, $letter-spacing: -0.005em),
    $headline-4:     mat.define-typography-level(36px, 42px, 400),
    $headline-5:     mat.define-typography-level(26px, 34px, 400),
    $headline-6:     mat.define-typography-level(22px, 34px, 500),
    $subtitle-1:  mat.define-typography-level(18px, 30px, 400),
    $subtitle-2:  mat.define-typography-level(17px, 26px, 400),
    $body-2:        mat.define-typography-level(16px, 26px, 500),
    $body-1:        mat.define-typography-level(16px, 22px, 400),
    $caption:       mat.define-typography-level(14px, 22px, 400),
    $button:        mat.define-typography-level(16px, 16px, 500),
);
/* stylelint-enable function-parentheses-space-inside */
// ------------------------------------

// Material Icons
// ------------------------------------

$material-icons-font-path: '/assets/fonts/MaterialIcons/';

@import 'material-icons/iconfont/material-icons';

// ------------------------------------
