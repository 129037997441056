/* http://mcg.mbitson.com */
$md-primary: (
    50 : #e0f5f9,
    100 : #b3e7f1,
    200 : #80d7e8,
    300 : #4dc7de,
    400 : #26bbd7,
    500 : #00afd0,
    600 : #00a8cb,
    700 : #009fc4,
    800 : #0096be,
    900 : #0086b3,
    A100 : #dcf5ff,
    A200 : #a9e6ff,
    A400 : #76d7ff,
    A700 : #5dd0ff,
    contrast: (
        50 : #000,
        100 : #000,
        200 : #000,
        300 : #000,
        400 : #000,
        500 : #fff,
        600 : #fff,
        700 : #fff,
        800 : #fff,
        900 : #fff,
        A100 : #000,
        A200 : #000,
        A400 : #000,
        A700 : #000,
    )
);

$md-secondary: (
    50 : #fbfbfb,
    100 : #f5f5f5,
    200 : #eee,
    300 : #e7e7e7,
    400 : #e2e2e2,
    500 : #ddd,
    600 : #d9d9d9,
    700 : #d4d4d4,
    800 : #cfcfcf,
    900 : #c7c7c7,
    A100 : #fff,
    A200 : #fff,
    A400 : #fff,
    A700 : #fff,
    contrast: (
        50 : #000,
        100 : #000,
        200 : #000,
        300 : #000,
        400 : #000,
        500 : #000,
        600 : #000,
        700 : #000,
        800 : #000,
        900 : #000,
        A100 : #000,
        A200 : #000,
        A400 : #000,
        A700 : #000,
    )
);

$md-tertiary: (
    50 : #fefefe,
    100 : #fcfcfc,
    200 : #fbfbfb,
    300 : #f9f9f9,
    400 : #f7f7f7,
    500 : #f6f6f6,
    600 : #f5f5f5,
    700 : #f3f3f3,
    800 : #f2f2f2,
    900 : #efefef,
    A100 : #fff,
    A200 : #fff,
    A400 : #fff,
    A700 : #fff,
    contrast: (
        50 : #000,
        100 : #000,
        200 : #000,
        300 : #000,
        400 : #000,
        500 : #000,
        600 : #000,
        700 : #000,
        800 : #000,
        900 : #000,
        A100 : #000,
        A200 : #000,
        A400 : #000,
        A700 : #000,
    )
);


$md-success: (
    50: #e0ede0,
    100: #b3d1b3,
    200: #80b380,
    300: #4d944d,
    400: #267d26,
    500: #060,
    600: #005e00,
    700: #005300,
    800: #004900,
    900: #003800,
    A100: #6eff6e,
    A200: #3bff3b,
    A400: #08ff08,
    A700: #0e0,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #fff,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    )
);


$md-background: (
    50 : #fefefe,
    100 : #fdfdfd,
    200 : #fcfcfc,
    300 : #fbfbfb,
    400 : #fafafa,
    500 : #f9f9f9,
    600 : #f8f8f8,
    700 : #f7f7f7,
    800 : #f6f6f6,
    900 : #f5f5f5,
    A100 : #fff,
    A200 : #fff,
    A400 : #fff,
    A700 : #fff,
    contrast: (
        50 : #000,
        100 : #000,
        200 : #000,
        300 : #000,
        400 : #000,
        500 : #000,
        600 : #000,
        700 : #000,
        800 : #000,
        900 : #000,
        A100 : #000,
        A200 : #000,
        A400 : #000,
        A700 : #000,
    )
);

$md-warning: (
    50: #fcf7e9,
    100: #f6eac9,
    200: #f1dca5,
    300: #ebce80,
    400: #e6c465,
    500: #e2b94a,
    600: #dfb243,
    700: #daaa3a,
    800: #d6a232,
    900: #cf9322,
    A100: #fff,
    A200: #fff0d5,
    A400: #ffdda2,
    A700: #ffd389,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #000,
        500: #000,
        600: #000,
        700: #000,
        800: #000,
        900: #000,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    )
);

$md-danger: (
    50: #fce0e4,
    100: #f6b3ba,
    200: #f1808d,
    300: #eb4d5f,
    400: #e6263c,
    500: #e2001a,
    600: #df0017,
    700: #da0013,
    800: #d6000f,
    900: #cf0008,
    A100: #fff6f7,
    A200: #ffc3c4,
    A400: #ff9092,
    A700: #ff7778,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #fff,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    )
);

$md-dark: (
    50 : #e0ebed,
    100 : #b3ced3,
    200 : #80aeb6,
    300 : #4d8d99,
    400 : #267483,
    500 : #005c6d,
    600 : #005465,
    700 : #004a5a,
    800 : #004150,
    900 : #00303e,
    A100 : #74d8ff,
    A200 : #41c9ff,
    A400 : #0ebbff,
    A700 : #00aef3,
    contrast: (
        50 : #000,
        100 : #000,
        200 : #000,
        300 : #fff,
        400 : #fff,
        500 : #fff,
        600 : #fff,
        700 : #fff,
        800 : #fff,
        900 : #fff,
        A100 : #000,
        A200 : #000,
        A400 : #000,
        A700 : #000,
    )
);

$md-light: (
    50: #fff,
    100: #fff,
    200: #fff,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #fff,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #000,
        500: #000,
        600: #000,
        700: #000,
        800: #000,
        900: #000,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);

$md-grey: (
    50: #f8f8f9,
    100: #efeef0,
    200: #e4e3e6,
    300: #d9d8db,
    400: #d0cfd4,
    500: #c8c7cc,
    600: #c2c1c7,
    700: #bbbac0,
    800: #b4b3b9,
    900: #a7a6ad,
    A100: #fff,
    A200: #fff,
    A400: #f6f5ff,
    A700: #e0dbff,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #000,
        500: #000,
        600: #000,
        700: #000,
        800: #000,
        900: #000,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);

$md-font: (
    50 : #e8e8ec,
    100 : #c7c6ce,
    200 : #a1a1ae,
    300 : #7b7b8e,
    400 : #5f5e75,
    500 : #43425d,
    600 : #3d3c55,
    700 : #34334b,
    800 : #2c2b41,
    900 : #1e1d30,
    A100 : #77f,
    A200 : #44f,
    A400 : #11f,
    A700 : #0000f6,
    contrast: (
        50 : #000,
        100 : #000,
        200 : #000,
        300 : #fff,
        400 : #fff,
        500 : #fff,
        600 : #fff,
        700 : #fff,
        800 : #fff,
        900 : #fff,
        A100 : #000,
        A200 : #fff,
        A400 : #fff,
        A700 : #fff,
    )
);
