@use '@angular/material' as mat;
@use '../../../../../../apps/finprocess/src/app/themes/variables.scss' as variables; // TODO: remove when dark palette available in get theme color

.ucba-heading-container {
    overflow: hidden;
}

.ucba-heading {
    align-items: center;
    display: grid;
    grid-template-columns: minmax(250px, auto) 1fr auto;

    &.ucba-expandable {
        grid-template-columns: minmax(250px, auto) auto 1fr auto;
    }
}

.ucba-heading-expand-arrow {
    border-radius: 50%;
    display: inline-flex;
    height: 20px;
    margin: 0 0.3rem;
    position: relative;
    width: 20px;
    
    &::before {
        content: '';
        cursor: pointer;
        height: 100%;
        left: 50%;
        padding: 14px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 2;
    }

    &::after {
        border-style: solid;
        border-width: 0 2px 2px 0;
        content: '';
        display: block;
        left: 50%;
        padding: 3px;
        position: absolute;
        top: calc(50% - 1px);
        transform: translate(-50%, -50%) rotate(45deg);
        transition: 0.3s;
        z-index: 1;
    }

    &.is-expanded::after {
        top: calc(50% + 1px);
        transform: translate(-50%, -50%) rotate(-135deg);
    }
}

.ucba-heading-title {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.ucba-heading-actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.ucba-heading-large {
    box-sizing: border-box;
    min-height: 52px;
    padding: 0.2rem 2rem;
    position: relative;
    z-index: 3;

    .ucba-heading-title {
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.ucba-heading-medium {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    max-height: 35px;
    padding: 0.2rem 1.75rem;
    position: relative;
    z-index: 2;

    .ucba-heading-title {
        font-weight: bold;
        text-transform: uppercase;
    }

    .ucba-heading-actions {
        max-height: 35px;
    }
}

.ucba-heading-small {
    font-weight: normal;
    margin: 0.15rem 0;
    max-height: 45px;
    min-height: 45px;
    padding: 0 1.75rem;
    position: relative;
    z-index: 1;

    .ucba-heading-expand-arrow {
        margin-right: 5rem;
    }

    .ucba-heading-actions {
        max-height: 45px;
    }
}


@mixin heading-theme($theme) {
    .ucba-heading-large {
        // background-color: mat.get-theme-color($theme, 'primary', 'darker');
        background-color: mat.get-color-from-palette(variables.$finprocess-dark, 400); // TODO: use get theme color when dark palette available

        .ucba-heading-expand-arrow {
            background-color: mat.get-theme-color($theme, 'primary', 400);

            &::after {
                border-color: mat.get-contrast-color-from-palette(variables.$finprocess-primary, 500);
            }
        }
    }
    
    .ucba-heading-medium {
        background-color: #fff;

        .ucba-heading-expand-arrow {
            background-color: #000;

            &::after {
                border-color: #fff;
            }
        }
    }

    .ucba-heading-small {
        // background-color: mat.get-theme-color($theme, 'accent', 'lighter');
        background-color: mat.get-color-from-palette(variables.$finprocess-dark, 50); // TODO: use get theme color when dark palette available
        color: mat.get-color-from-palette(variables.$finprocess-dark, 400) !important; // TODO: use get theme color when dark palette available

        .ucba-heading-expand-arrow {
            &::after {
                // border-color: mat.get-theme-color($theme, 'accent', 'contrast-lighter');
                border-color: mat.get-color-from-palette(variables.$finprocess-dark, 900) !important; // TODO: use get theme color when dark palette available
            }
        }
    }
}
