@use '@angular/material' as mat;
@use '../../../../../../apps/finprocess/src/app/themes/variables.scss' as variables;

@mixin table-theme($theme) {
    .ucba-table-row {
        &:nth-child(odd) {
            > :nth-child(odd) {             
                background-color: mat.get-color-from-palette(variables.$finprocess-dark, 50);
            }
            
            > :nth-child(even) {
                background-color: mat.get-color-from-palette(variables.$finprocess-secondary, 100);           
            }
        }

        &.no-alternating-column-colors {
            &:nth-child(odd) > * {
                background-color: mat.get-color-from-palette(variables.$finprocess-dark, 50);
            }
        }

        &.highlighted-light {
            color: mat.get-color-from-palette(variables.$finprocess-primary, 400);
            font-weight: bold !important;

            > :nth-child(odd) {             
                background-color: mat.get-color-from-palette(variables.$finprocess-primary, 50);
            }

            &.no-alternating-column-colors {
                /* stylelint-disable-next-line max-nesting-depth */
                > :nth-child(even) {             
                    background-color: mat.get-color-from-palette(variables.$finprocess-primary, 50);
                }
            }
        }

        &.highlighted-dark {
            color: #fff;
            font-weight: bold !important;

            > :nth-child(odd) {             
                background-color: mat.get-color-from-palette(variables.$finprocess-primary, 400);
            }

            &.no-alternating-column-colors {
                /* stylelint-disable-next-line max-nesting-depth */
                > :nth-child(even) {             
                    background-color: mat.get-color-from-palette(variables.$finprocess-primary, 400);
                }
            }
        }
    }

    .ucba-table-header {
        &:not(.no-alternating-column-colors) > :nth-child(odd) {
            background-color: mat.get-color-from-palette(variables.$finprocess-dark, 400) !important;
            color: #fff !important;
        }

        &:not(.no-alternating-column-colors) > :nth-child(even) {
            background-color: mat.get-color-from-palette(variables.$finprocess-dark, 100) !important;
            color: mat.get-color-from-palette(variables.$finprocess-dark, 400) !important;
        }

        &.no-alternating-column-colors > * {
            background-color: mat.get-color-from-palette(variables.$finprocess-dark, 400) !important;
            color: #fff !important;
        }
    }
}

.ucba-table {
    display: grid;
    max-height: 100%;
}

.ucba-table-row {
    display: grid;
    font-weight: normal;
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
   
    > * {
        padding: 0.5rem 1rem;

        &.no-styles {
            background-color: transparent !important;
            padding: 0 !important;
        }
    }
}

.ucba-table-header {
    color: #fff;
    font-weight: bold;
    position: sticky;
    text-transform: uppercase;
    top: -0.5rem;
    z-index: 1;

    > * {
        padding: 0.8rem 1rem;
    }
}

.ucba-table-header,
.ucba-table-row {
    > *:first-child {
        padding-left: 2rem;
    }

    > *:last-child {
        padding-right: 2rem;
    }
}

.row-header-comment {
    font-size: 12px;
    margin-left: 4px;
}
